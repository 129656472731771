import React from "react";
const Location = () => {
  return (
    <svg
      width="56"
      height="66"
      viewBox="0 0 56 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Location">
        <path
          id="Subtract"
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28 0.541626C12.7206 0.541626 0.291664 12.8055 0.291664 27.9861C0.291664 37.4276 5.79761 46.746 11.6329 53.5291C14.5859 56.9617 17.7201 59.8596 20.4847 61.9206C21.8648 62.9494 23.1912 63.7984 24.3885 64.4014C25.5109 64.9667 26.7927 65.4583 28 65.4583C29.2073 65.4583 30.4891 64.9667 31.6115 64.4014C32.8088 63.7984 34.1352 62.9494 35.5153 61.9206C38.2799 59.8596 41.4141 56.9617 44.3671 53.5291C50.2024 46.746 55.7083 37.4276 55.7083 27.9861C55.7083 12.8055 43.2794 0.541626 28 0.541626Z"
          fill="#69AD69"
        />
        <circle
          id="Ellipse 33"
          cx="9.5"
          cy="9.5"
          r="9.5"
          transform="matrix(-1 0 0 1 37.5 17.1666)"
          fill="#69AD69"
        />
      </g>
    </svg>
  );
};
export default Location;
