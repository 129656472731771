import React from "react";
const Chart = () => {
  return (
    <svg
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Chart">
        <path
          id="Subtract"
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.9583 0.635437C10.182 0.635437 0.635376 10.182 0.635376 21.9584V59.0417C0.635376 70.818 10.182 80.3646 21.9583 80.3646H59.0416C70.8179 80.3646 80.3645 70.818 80.3645 59.0417V21.9584C80.3645 10.182 70.8179 0.635437 59.0416 0.635437H21.9583Z"
          fill="#73BE73"
        />
        <path
          id="Line"
          d="M25.6666 59.0417L25.6666 47.9167"
          stroke="#73BE73"
          stroke-width="5.5625"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Line_2"
          d="M40.5 59.0417L40.5 21.9584"
          stroke="#73BE73"
          stroke-width="5.5625"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Line_3"
          d="M55.3333 59.0417L55.3333 33.0834"
          stroke="#73BE73"
          stroke-width="5.5625"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default Chart;
