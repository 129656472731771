import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/pricing.css";
import { Link } from "react-router-dom";
const PriceInfo = () => {
  return (
    <div className="price-body">
      <div className="price-info-container">
        <h2 className="price-title">Pro</h2>
        <h3 className="price-info">
          Get access to all of our amazing features enhance your shop's
          scheduling.
        </h3>
        <div className="price-container">
          <h1 className="price">$299.00</h1>
          <h1 className="price-annual">annualy</h1>
        </div>

        <ul className="item-list">
          <li>Google calendar integration</li>
          <li>Real-time availibility</li>
          <li>Email notifications</li>
          <li>Intelligent analytics and reports</li>
          <li>Customize your service offerings</li>
          <li>Dedicated support</li>
        </ul>
        <Link to="/sign-up" className="price-signup">
          Get started
        </Link>
      </div>
    </div>
  );
};
const Pricing = () => {
  return (
    <div>
      <Header />
      <PriceInfo />
      <Footer />
    </div>
  );
};

export default Pricing;
