import React from "react";
import Logo from "../graphics/LogoDark.js";
import "../css/footer.css";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handleNavigateAndScroll = (route, elementId) => {
    navigate(`/${route}`);
    setTimeout(() => {
      const element = document.getElementById(elementId);
      // if (element) {
      //   element.scrollIntoView({ behavior: "smooth" });

      // }
      if (element) {
        if (route === "resources") {
          // Scroll to the element without smooth behavior
          element.scrollIntoView({ behavior: "auto" });

          // Then, adjust the scroll position by subtracting 100 pixels with smooth behavior
          window.scrollTo({
            top: window.scrollY - 100,
            behavior: "smooth",
          });
        } else {
          // For other routes, scroll to the element with smooth behavior
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 0);
  };
  return (
    <div>
      <div className="upgrade-container">
        <div className="title-container">
          {/* <div className="straight-line" /> */}
          <div className="upgrade-title">Upgrade your service</div>
          {/* <div className="straight-line" /> */}
        </div>
        <div className="buttons-container">
          <Link to="/sign-up" className="button-signup">
            {" "}
            Sign Up
          </Link>

          {/* <a
            href="http://www.demo.repairshopapp.com"
            className="button-viewdemo"
          >
            {" "}
            View Demo
          </a> */}
        </div>
      </div>
      {/* Upgrade Container End */}
      <div className="links-container">
        <div className="links-logo">
          <Logo />
          <h1 className="slogan">Transforming one repair shop at a time</h1>
        </div>
        <div className="nav-container">
          <h1 className="links-title">Product</h1>
          <a
            onClick={() => handleNavigateAndScroll("features", "booking")}
            className="link-footer"
          >
            Booking
          </a>
          <a
            onClick={() => handleNavigateAndScroll("features", "availibility")}
            className="link-footer"
          >
            Availibility Sync
          </a>
          <a
            onClick={() => handleNavigateAndScroll("features", "email")}
            className="link-footer"
          >
            Email Integration
          </a>
          <a
            onClick={() => handleNavigateAndScroll("features", "customization")}
            className="link-footer"
          >
            Customizations
          </a>

          {/* <ScrollLink to="availibility" className="link-footer">
            Availibitlity Sync
          </ScrollLink>
          <ScrollLink to="email" className="link-footer">
            Email Integration
          </ScrollLink>
          <ScrollLink to="customization" className="link-footer">
            Customizations
          </ScrollLink> */}
        </div>
        <div className="nav-container">
          <h1 className="links-title">Resources</h1>
          <a
            onClick={() =>
              handleNavigateAndScroll("resources", "guide-section")
            }
            className="link-footer"
          >
            Guides
          </a>
          <a
            onClick={() =>
              handleNavigateAndScroll("resources", "article-section")
            }
            className="link-footer"
          >
            Articles
          </a>
          <a
            onClick={() => handleNavigateAndScroll("resources", "new-section")}
            className="link-footer"
          >
            What's New
          </a>
          <a
            onClick={() => handleNavigateAndScroll("resources", "help-section")}
            className="link-footer"
          >
            Help
          </a>
        </div>
        {/* <div className="nav-container">
          <h1 className="links-title">Company</h1>
          <a href="#" className="link-footer">
            Contact
          </a>
          <a href="#" className="link-footer">
            About Us
          </a>
        </div> */}
        {/* <div className="nav-container"> */}
          {/* <a href="#demo_btn" className="links-title">
            Demo
          </a> */}
          {/* <a className="link-footer" target="_blank" rel="noopener noreferrer"
          href="https://www.demo.repairshopapp.com">
            Client
          </a>
          <a className="link-footer" target="_blank" rel="noopener noreferrer"
          href="https://demo.repairshopapp.com/index.php/backend">
            Admin
          </a> */}
        {/* </div> */}
      </div>
      {/* Links container end */}
    </div>
  );
};

export default Footer;
