import "../css/landing.css";
import "../css/exploreDemo.css";
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';

const ExploreDemo = () => {

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          margin: '10px',
        },
    };
      
      
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
  
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }


    return (
        <div id="demo_btn" className="top-landing-demo-btn">
            <button onClick={openModal}>Explore Demo</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h2 className="top-landing-demo-modal-h2" ref={(_subtitle) => (subtitle = _subtitle)}>Backend Login:</h2>
                <div className="top-landing-demo-modal">
                    <span>User: Admin</span>
                    <span>Pass: AdminPW</span>
                </div>
                <a
                    target="_blank" rel="noopener noreferrer"
                    href="https://demo.repairshopapp.com"
                    className="top-landing-demo-btn"
                >
                    Client
                </a>
                <a
                    target="_blank" rel="noopener noreferrer"
                    href="https://demo.repairshopapp.com/index.php/backend"
                    className="top-landing-demo-btn"
                >
                    Backend
                </a>
            </Modal>
        </div>
   );
};

export default ExploreDemo;