import React from "react";
const Users = () => {
  return (
    <svg
      width="81"
      height="61"
      viewBox="0 0 81 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Users">
        <path
          id="Subtract"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M70.3051 55.1595C70.507 55.1862 70.7089 55.2137 70.9106 55.2421L71.9249 55.3844C76.3409 56.004 80.2918 52.6382 80.2918 48.2565V44.9124C80.2918 41.7787 78.2862 38.9832 75.2818 37.9292C71.8105 36.7115 68.2269 35.9186 64.6094 35.5506C68.3841 38.2796 70.7253 42.6989 70.7253 47.5202V51.6719C70.7253 52.8777 70.5793 54.0453 70.3051 55.1595ZM55.0051 31.1019C56.5094 31.7229 58.1619 32.0662 59.8959 32.0662C66.8725 32.0662 72.5281 26.5108 72.5281 19.658C72.5281 12.8051 66.8725 7.24976 59.8959 7.24976C59.6892 7.24976 59.4837 7.25463 59.2795 7.26427C60.5481 9.9662 61.2571 12.9831 61.2571 16.1655C61.2571 22.0117 58.8646 27.2991 55.0051 31.1019Z"
          fill="#73BE73"
        />
        <path
          id="Subtract_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1073 55.1595C9.90533 55.1862 9.70348 55.2137 9.50172 55.2421L8.48746 55.3844C4.07149 56.004 0.120581 52.6382 0.120581 48.2565V44.9124C0.120581 41.7787 2.12614 38.9832 5.1306 37.9292C8.60181 36.7115 12.1854 35.9186 15.803 35.5506C12.0283 38.2796 9.6871 42.6989 9.6871 47.5202V51.6719C9.6871 52.8777 9.83301 54.0453 10.1073 55.1595ZM25.4072 31.1019C23.9029 31.7229 22.2505 32.0662 20.5164 32.0662C13.5399 32.0662 7.88427 26.5108 7.88427 19.658C7.88427 12.8051 13.5399 7.24976 20.5164 7.24976C20.7231 7.24976 20.9286 7.25463 21.1329 7.26427C19.8642 9.9662 19.1552 12.9831 19.1552 16.1655C19.1552 22.0117 21.5478 27.2991 25.4072 31.1019Z"
          fill="#73BE73"
        />
        <path
          id="Vector"
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.5257 38.8501C33.6594 34.5167 46.919 34.5167 59.0528 38.8501C62.7168 40.1587 65.1627 43.6294 65.1627 47.5201V51.6719C65.1627 57.112 60.3444 61.2909 54.959 60.5215L53.722 60.3448C44.8121 59.072 35.7664 59.072 26.8564 60.3448L25.6195 60.5215C20.234 61.2909 15.4158 57.112 15.4158 51.6719V47.5201C15.4158 43.6294 17.8616 40.1587 21.5257 38.8501Z"
          fill="#73BE73"
        />
        <path
          id="Vector_2"
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M55.6947 16.1656C55.6947 7.65741 48.7975 0.760193 40.2893 0.760193C31.7812 0.760193 24.884 7.65741 24.884 16.1656C24.884 24.6737 31.7812 31.5709 40.2893 31.5709C48.7975 31.5709 55.6947 24.6737 55.6947 16.1656Z"
          fill="#73BE73"
        />
      </g>
    </svg>
  );
};

export default Users;
