import React, { useEffect, useState } from "react";
import "../css/landing.css";
import landingImgTop from "../graphics/top-landing-img.png";
import infoImg0 from "../graphics/info-graphic-img-appointment.png";
import infoImg1 from "../graphics/client-management.png";
import infoImg2 from "../graphics/business-workflow.png";
import Calendar from "../graphics/Calendar.js";
import AddUser from "../graphics/AddUser.js";
import Location from "../graphics/Location.js";
import Edit from "../graphics/Edit.js";
import mechanic from "../graphics/mechanic.jpg";
import Chart from "../graphics/Chart.js";
import Users from "../graphics/Users.js";


import ExploreDemo from "../components/ExploreDemo.jsx";

const Landing = () => {

  
  const [currentFeature, setCurrentFeature] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);

  

  const handlePressedFeature = (button) => {
    setCurrentFeature(button);
  };
  const data = [
    {
      title: "Appointments",
      subtitle: "Coordination for optimal efficiency",
      text: "Transform your appointment logistics and mitigate<br /> scheduling uncertainties by directly engaging with the <br />client's appointments",
      logoImg: Calendar,
      infoImg: infoImg0,
    },
    {
      title: "Client Management",
      subtitle: "Coordination for optimal efficiency",
      text: "Enhance customer relationships and streamline operations<br /> by actively managing and leveraging comprehensive user <br />data.",
      logoImg: Users,
      infoImg: infoImg1,
    },
    {
      title: "Business Workflow",
      subtitle: "Design for your success",
      text: "Tailor the system to your preferences, set employee<br /> working hours and empower customers to access available<br /> time frames, all with Google calendar integration.",
      logoImg: Chart,
      infoImg: infoImg2,
    },
  ];
  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [currentFeature]);

  const renderBtnsInfo = (btnPressed) => {
    const Icon = data[btnPressed].logoImg;

    return (
      <div className="bottom-landing-btns-info">
        <div className="info-left">
          <div className="bottom-landing-btns-info-header">
            <Icon />
            <div className="header-container">
              <div className="info-header">{data[btnPressed].title}</div>
              <div className="info-header-subtext">
                {data[btnPressed].subtitle}
              </div>
            </div>
          </div>
          {/* End of sub-title */}
          <div
            dangerouslySetInnerHTML={{ __html: data[btnPressed].text }}
            className="info-text"
          />
          {/* <div className="info-btn">Learn More</div> */}
        </div>
        <div className="img-container">
          <div className="info-graphic-img" key={animationKey}>
            <img
              src={data[btnPressed].infoImg}
              alt="Info Graphic"
              className="info-img"
            />
          </div>
          <div className="info-graphic-rect" />
        </div>
        {/* End image container */}
      </div>
    );
  };

  return (
    <div>
      <div className="top-landing">
        <div className="top-landing-text">
          Online appointments scheduling
          <br />
          for your clients
        </div>
        <div className="top-landing-text-small">
          Maximize your business’ potential with effortless client scheduling,
          seamless calendar coordination, workflow automation and more.
        </div>
        <ExploreDemo />
        <img
          src={landingImgTop}
          alt="Top Landing"
          className="top-landing-img"
        />
      </div>
      {/* end of top div */}
      <div className="bottom-landing">
        <div className="bottom-landing-text-large">
          Maximize your business at every step
        </div>
        <div className="bottom-landing-buttons">
          <div
            className={
              currentFeature === 0
                ? "bottom-landing-btn-active"
                : "bottom-landing-btn-inactive"
            }
            onClick={() => handlePressedFeature(0)}
          >
            Appointments
          </div>
          <div
            className={
              currentFeature === 1
                ? "bottom-landing-btn-active"
                : "bottom-landing-btn-inactive"
            }
            onClick={() => handlePressedFeature(1)}
          >
            Client Management
          </div>
          <div
            className={
              currentFeature === 2
                ? "bottom-landing-btn-active"
                : "bottom-landing-btn-inactive"
            }
            onClick={() => handlePressedFeature(2)}
          >
            Business Workflow
          </div>
        </div>
        {/* End of buttons */}
        {renderBtnsInfo(currentFeature)}

        {/* End of bottom landing under btns info */}
        <div className="how-it-works">How it works</div>
        <div className="bottom-landing-text-large-2">
          Register today. Deploy tomorrow.
        </div>
        <div className="steps">
          <div className="steps-individual">
            <div className="steps-icon">
              <AddUser />
            </div>
            <h1 className="steps-text-header">1. Sign Up</h1>
            <h1 className="steps-text-main">
              <span className="main-text-bold">
                Create your profile with your business and contact information.
              </span>{" "}
              Repair shop app will handle website template generation.
            </h1>
          </div>
          <div className="steps-individual">
            <div className="steps-icon">
              <Edit />
            </div>
            <h1 className="steps-text-header">2. Set Up</h1>
            <h1 className="steps-text-main">
              <span className="main-text-bold">
                Fill out employee and service information, along with preferred
                shop style.
              </span>{" "}
              UI will be automatically updated to reflect changes.
            </h1>
          </div>
          <div className="steps-individual">
            <div className="steps-icon">
              <Location />
            </div>
            <h1 className="steps-text-header">3. Launch</h1>
            <h1 className="steps-text-main">
              <span className="main-text-bold">
                Integrate with existing shop website.
              </span>{" "}
              Access repair shop app domain to host as standalone service.
            </h1>
          </div>
        </div>
        {/* End of Steps */}
        <div className="features-container">
          <div>
            <h1 className="features-header">
              The features you need to succeed
              <span className="features-header-period">.</span>
            </h1>
            <div className="features-grid">
              <div className="feature">
                <h1 className="feature-title">Calendar Sync</h1>
                <h1 className="feature-text">
                  Synchronize your events with google calendar, keeping
                  employees and clients on track.
                </h1>
              </div>
              <div className="feature">
                <h1 className="feature-title">Customer Profile</h1>
                <h1 className="feature-text">
                  Leverage vehicle information for personalized, proactive and
                  efficient service.
                </h1>
              </div>
              <div className="feature">
                <h1 className="feature-title">Email Integration</h1>
                <h1 className="feature-text">
                  Get email updates for booking changes and send customer
                  reminders about their appointments.
                </h1>
              </div>
              <div className="feature">
                <h1 className="feature-title">Service Config</h1>
                <h1 className="feature-text">
                  Customize the details of service offerings to provide optimal
                  service packages.
                </h1>
              </div>
            </div>
          </div>
          {/* End of Features grid */}
          <div className="mechanic-div">
            <img src={mechanic} alt="Mechanic" className="mechanic-img" />
          </div>
        </div>
        {/* End of Features Container */}
        <ExploreDemo />
      </div>
    </div>
  );
};

export default Landing;
