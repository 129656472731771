import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/features.css";
import React from "react";

const FeatureInfo = () => {
  return (
    <div className="features-main">
      <div className="feature-container dark" id="booking">
        <div className="feature-1 text-light">
          <h1>Booking</h1>
          <p>
            Our easy-to-use online scheduling software enables customers to book
            appointments with just a few clicks. Whether they're on their
            desktops or mobile devices, the booking process is straightforward
            and hassle-free, increasing customer satisfaction and loyalty. No
            more phone tag or missed calls!
          </p>
        </div>
        {/* <div className="features-image"></div> */}
      </div>
      <div className="feature-container light-g" id="availibility">
        <div className="feature-1 text-light">
          <h1>Availibility Sync</h1>
          <p>
            Our easy-to-use online scheduling software enables customers to book
            appointments with just a few clicks. Whether they're on their
            desktops or mobile devices, the booking process is straightforward
            and hassle-free, increasing customer satisfaction and loyalty. No
            more phone tag or missed calls!
          </p>
        </div>
        {/* <div className="features-image"></div> */}
      </div>
      <div className="feature-container lighter-g" id="email">
        <div className="feature-1 text-dark">
          <h1>Email Integration</h1>
          <p>
            Effortlessly integrate all your scheduled auto shop appointments
            with Google Calendar. Our software syncs your bookings in real-time,
            merging them directly into your Google Calendar. This integration
            eliminates the need for manual data entry, prevents scheduling
            conflicts, and gives you instant access to your appointments
            wherever you are.
          </p>
        </div>
        {/* <div className="features-image"></div> */}
      </div>
      <div className="feature-container lightest-g" id="customization">
        <div className="feature-1 text-dark">
          <h1>Customizations</h1>
          <p>
            Tailor our scheduling software to meet your unique business needs.
            Customize user roles, services, booking durations, pricing, and more
            to reflect your auto shop's branding and operational requirements.
            We offer flexibility, so you have complete control.
          </p>
        </div>
        {/* <div className="features-image"></div> */}
      </div>
    </div>
  );
};
const Features = () => {
  return (
    <div>
      <Header />
      <FeatureInfo />
      <Footer />
    </div>
  );
};

export default Features;
