import React, { useEffect } from "react";

import "./css/app.css";
import { Element } from "react-scroll";

import { Routes, Route, useLocation } from "react-router-dom";
import SignupForm from "./_auth/forms/SignupForm";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Product from "./pages/Product";
import Resources from "./pages/Resources";
import Features from "./pages/Features";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Element>
      <div>
        <Routes>
          {/* Public Routes */}
          <Route index element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/sign-up" element={<SignupForm />} />
          <Route path="/product" element={<Product />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/features" element={<Features />} />

          {/* Private Routes */}
        </Routes>
      </div>
    </Element>
  );
}

export default App;
