import React from "react";
const Calendar = () => {
  return (
    <svg
      width="73"
      height="79"
      viewBox="0 0 73 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Calendar">
        <path
          id="Exclude"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.492249 24.6341C1.85009 14.2102 10.764 6.16035 21.558 6.16035H51.116C61.91 6.16035 70.8239 14.2102 72.1817 24.6341H0.492249ZM52.9633 0.618225C51.4329 0.618225 50.1923 1.85887 50.1923 3.38929V14.4735C50.1923 16.004 51.4329 17.2446 52.9633 17.2446C54.4938 17.2446 55.7344 16.004 55.7344 14.4735V3.38929C55.7344 1.85887 54.4938 0.618225 52.9633 0.618225ZM19.7106 0.618225C18.1802 0.618225 16.9395 1.85887 16.9395 3.38929V14.4735C16.9395 16.004 18.1802 17.2446 19.7106 17.2446C21.241 17.2446 22.4817 16.004 22.4817 14.4735V3.38929C22.4817 1.85887 21.241 0.618225 19.7106 0.618225Z"
          fill="#69AD69"
        />
        <path
          id="Subtract"
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.313171 30.1762V56.9631C0.313171 68.6963 9.8248 78.208 21.558 78.208H51.116C62.8492 78.208 72.3608 68.6963 72.3608 56.9631V30.1762H0.313171ZM16.0159 44.9552C14.4855 44.9552 13.2448 43.7146 13.2448 42.1841C13.2448 40.6537 14.4855 39.4131 16.0159 39.4131H19.7106C21.241 39.4131 22.4817 40.6537 22.4817 42.1841C22.4817 43.7146 21.241 44.9552 19.7106 44.9552H16.0159ZM31.7186 42.1841C31.7186 43.7146 32.9592 44.9552 34.4896 44.9552H38.1844C39.7148 44.9552 40.9554 43.7146 40.9554 42.1841C40.9554 40.6537 39.7148 39.4131 38.1844 39.4131H34.4896C32.9592 39.4131 31.7186 40.6537 31.7186 42.1841ZM50.1923 42.1841C50.1923 43.7146 51.433 44.9552 52.9634 44.9552H56.6581C58.1885 44.9552 59.4292 43.7146 59.4292 42.1841C59.4292 40.6537 58.1885 39.4131 56.6581 39.4131H52.9634C51.433 39.4131 50.1923 40.6537 50.1923 42.1841ZM13.2448 56.9631C13.2448 58.4936 14.4855 59.7342 16.0159 59.7342H19.7106C21.241 59.7342 22.4817 58.4936 22.4817 56.9631C22.4817 55.4327 21.241 54.1921 19.7106 54.1921H16.0159C14.4855 54.1921 13.2448 55.4327 13.2448 56.9631ZM31.7186 56.9631C31.7186 58.4936 32.9592 59.7342 34.4896 59.7342H38.1844C39.7148 59.7342 40.9554 58.4936 40.9554 56.9631C40.9554 55.4327 39.7148 54.1921 38.1844 54.1921H34.4896C32.9592 54.1921 31.7186 55.4327 31.7186 56.9631ZM50.1923 56.9631C50.1923 58.4936 51.433 59.7342 52.9634 59.7342H56.6581C58.1885 59.7342 59.4292 58.4936 59.4292 56.9631C59.4292 55.4327 58.1885 54.1921 56.6581 54.1921H52.9634C51.433 54.1921 50.1923 55.4327 50.1923 56.9631Z"
          fill="#69AD69"
        />
      </g>
    </svg>
  );
};
export default Calendar;
