import React from "react";
const AddUser = () => {
  return (
    <svg
      width="62"
      height="60"
      viewBox="0 0 62 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Add user">
        <circle
          id="Ellipse 33"
          opacity="0.3"
          cx="12.6667"
          cy="12.6667"
          r="12.6667"
          transform="matrix(-1 0 0 1 37.3333 2.5)"
          fill="#69AD69"
          stroke="#69AD69"
          stroke-width="4.75"
        />
        <path
          id="Rectangle 2"
          d="M2.5 46.6264C2.5 43.9019 4.2127 41.4716 6.77846 40.5553C18.3461 36.424 30.9872 36.424 42.5549 40.5553C45.1206 41.4716 46.8333 43.9019 46.8333 46.6264V50.7922C46.8333 54.5524 43.5029 57.4409 39.7805 56.9091L36.7582 56.4773C28.7379 55.3316 20.5954 55.3316 12.5751 56.4773L9.55283 56.9091C5.8304 57.4409 2.5 54.5524 2.5 50.7922V46.6264Z"
          fill="#69AD69"
          stroke="#69AD69"
          stroke-width="4.75"
        />
        <path
          id="Line"
          d="M46.8333 27.8334H59.5"
          stroke="#69AD69"
          stroke-width="4.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Line_2"
          d="M53.1667 21.5L53.1667 34.1666"
          stroke="#69AD69"
          stroke-width="4.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default AddUser;
