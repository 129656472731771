import React from "react";
import "../../css/auth.css";
import LogoDark from "../../graphics/LogoDark.js";
// import Calendar from "../../graphics/calender-product.png";
import { Link } from "react-router-dom";

// const ProductDisplay = () => {
//   return (
//     <section>
//       <div className="product">
//         <img src={Calendar} alt="Product Image" />
//         <div className="description">
//           <h3>Appointment Application</h3>
//           <h5>$299.00</h5>
//         </div>
//       </div>
//       <form action="/create-checkout-session" method="POST">
//         <button type="submit" className="checkout">
//           Checkout
//         </button>
//       </form>
//     </section>
//   );
// };

const SignupForm = () => {
  return (
    <div className="background overflow-hidden">
      <div className="description-container">
        <div className="info-and-img-container">
          <LogoDark />
          <h2 className="pt-4">How it works</h2>
        </div>

        <ul className="information-list">
          <li>
            After checking out, you will be contacted about setting up
            appointment scheduling for your business.
          </li>
          <li>
            You will be provided a domain to fill in your business information.
          </li>
          <li>Tailor the application for your unqiue business needs.</li>
        </ul>

        <stripe-buy-button
          buy-button-id="buy_btn_1OUCnKHuPo8X35rsDCrESOXX"
          publishable-key="pk_live_51OSp09HuPo8X35rsfz5Cml6MPSuqVNnqYZ3BTBxxhJqONt2YCaYjmtuS2bE9uraBcd8KenWMpJKBJAGa8Q2CebxW006SkVdX2i"
        />
        <Link className="back-button" to="/">
          Return
        </Link>
      </div>
    </div>
  );
};

export default SignupForm;
