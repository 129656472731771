// Header.js
import Logo from "../graphics/Logo.js";
import React from "react";
import "../css/header.css";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

const Header = (props) => {
  return (
    <header className="header">
      <Link to="/">
        <Logo className="logo" />
      </Link>
      <nav className="navigation">
        <Link to="/product" className="link">
          Product
        </Link>
        <Link to="/pricing" className="link">
          Pricing
        </Link>
        <Link to="/resources" className="link">
          Resources
        </Link>
        <Link to="/resources#help-section" smooth={true} duration={500} offset={-130} className="link">
          Contact Us
        </Link>
        {/* <a href="http://www.demo.repairshopapp.com" className="link">
          Demo
        </a> */}
      </nav>
      <div className="auth-section">
        {/* <a href="#" className="link">
          Login
        </a> */}
        <Link to="/sign-up" className="signup-section">
          <h1 className="link signup-link">Sign Up</h1>
        </Link>
      </div>
    </header>
  );
};

export default Header;
