import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/product.css";
import { useState, useEffect } from "react";
import client1 from "../graphics/client-demo-1.png";
import client2 from "../graphics/client-demo-2.png";
import client3 from "../graphics/client-demo-3.png";
import client4 from "../graphics/client-demo-4.png";
import admin1 from "../graphics/admin-demo-1.png";
import admin2 from "../graphics/admin-demo-2.png";
import admin3 from "../graphics/admin-demo-3.png";
import admin4 from "../graphics/admin-demo-4.png";
import { Link } from "react-router-dom";

const SlideshowClient = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [images]);

  const handleCircleClick = (index) => {
    setCurrentImage(index);
  };

  return (
    <div className="slideshow-container">
      <div className="slideshow">
        {images.map((image, index) => (
          <img
            key={index}
            className={`fade ${index === currentImage ? "show" : "hide"}`}
            src={image}
            alt={`Slide ${index + 1}`}
          />
        ))}
        <div className="tracker">
          {images.map((_, index) => (
            <div
              key={index}
              className={`circle ${index === currentImage ? "active" : ""}`}
              onClick={() => handleCircleClick(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};
const DisplayContainer = ({ type, images, content }) => {
  const handleScroll = (vh) => {
    const windowHeight = window.innerHeight;
    const scrollPos = (vh / 100) * windowHeight;
    window.scrollTo({ top: scrollPos, behavior: "smooth" });
  };
  return (
    <div className={`${type}-container`}>
      <div className="user-section">
        <div className={`${type}-text"`}>
          <div className="view-option">
            <h1
              className={`${type}-option1 pointer`}
              onClick={() => handleScroll(0)}
            >
              Client
            </h1>
            <h1
              className={`${type}-option2 pointer`}
              onClick={() => handleScroll(75)}
            >
              Owner
            </h1>
          </div>
          <div className={`${type}-title`}>{content[0]}</div>
          <div className={`${type}-info`}>{content[1]}</div>
          <Link to="/sign-up" className={`${type}-signup`}>
            Get Started
          </Link>
        </div>
        <SlideshowClient images={images} />
      </div>
    </div>
  );
};
const ProductInfo = () => {
  const ClientImages = [client1, client2, client3, client4];
  const AdminImages = [admin1, admin2, admin3, admin4];
  const ClientContent = [
    "Simplified booking process",
    "Get more clients in the door, with real-time availability and easy to use customer interface. Give your customers the ability to select the service and provider right for their repair",
  ];
  const AdminContent = [
    "Customizeable service options",
    "Stay in control of all client bookings, track customer history. Customize user roles, services, booking durations, pricing, and more to reflect your auto shop's branding and operational requirements. ",
  ];

  return (
    <div className="product-container">
      <DisplayContainer
        type="user"
        images={ClientImages}
        content={ClientContent}
      />
      <div className="admin-container">
        <DisplayContainer
          type="admin"
          images={AdminImages}
          content={AdminContent}
        />
      </div>
    </div>
  );
};
const Product = () => {
  return (
    <div>
      <Header />
      <ProductInfo />
      <Footer />
    </div>
  );
};

export default Product;
