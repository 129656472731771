import React from "react";
const Edit = () => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Edit 2">
        <path
          id="Subtract"
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.375 0.166626C8.31881 0.166626 0.166656 8.31878 0.166656 18.375V50.0416C0.166656 60.0978 8.31881 68.25 18.375 68.25H50.0417C60.0978 68.25 68.25 60.0978 68.25 50.0416V18.375C68.25 8.31878 60.0978 0.166626 50.0417 0.166626H18.375Z"
          fill="#69AD69"
        />
        <path
          id="Subtract_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M44.3998 18.6282C43.0397 17.2681 40.8353 17.2689 39.4762 18.63L35.8493 22.2625L46.2316 32.6448L49.8441 28.9384C51.1752 27.5727 51.1612 25.3896 49.8127 24.041L44.3998 18.6282ZM44.4083 34.5156L34.0036 24.1109L20.9455 37.1889C20.0207 38.1151 19.477 39.3549 19.4219 40.6632L19.169 46.6764C19.1065 48.1613 20.2926 49.3993 21.7777 49.3994L27.6999 49.3997C29.1076 49.3997 30.4556 48.8311 31.4385 47.8226L44.4083 34.5156Z"
          fill="#69AD69"
        />
      </g>
    </svg>
  );
};
export default Edit;
