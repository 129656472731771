import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/resources.css";
import { Link as ScrollLink } from "react-scroll";
import GetInTouch from "../components/GetInTouch.jsx";

const sidebarItems = [
  { id: 1, label: "Guides", target: "guide-section" },
  { id: 2, label: "Articles", target: "article-section" },
  { id: 3, label: "What's New", target: "new-section" },
  { id: 4, label: "Help", target: "help-section" },
];
const MainContent = () => {
  return (
    <div className="main-content">
      <section id="guide-section">
        <h2 className="main-title">Guides</h2>
        <h1 className="main-subtitle">Demo setup</h1>
        <div className="main-text">
          Getting started with the demo is a simple process, broken up into
          several components.
          <ul>
            <li>
              Setup: Fill in the information to create the business profile.
              Create a login email and password, this will be used to login to
              the admin side of the demo.
            </li>
            <li>
              Admin: Access this page through the login button on the buttom of
              the of the appointment page. On the admin page, you are able to
              add new services, providers, manage appointments and more. Explore
              and have fun.
            </li>
            <li>
              Client: After setting up various services and providers,
              experience the process of booking on the client side.
            </li>
          </ul>
        </div>
      </section>

      <section id="article-section">
        <h2 className="main-title">Articles</h2>
        <p className="main-subtitle">
          We will share exciting information regarding our product in the future
          here.
        </p>
      </section>
      <section id="new-section">
        <h2 className="main-title">What's new</h2>
        <h1 className="main-subtitle">Everything</h1>
        <p className="main-text">
          The product as a whole is preparing for launch, with so many exciting
          features coming soon. When something gets pushed out, you will be the
          first to know!
        </p>
      </section>
      <section id="help-section">
        <h2 className="main-title">Help</h2>
        <h1 className="main-subtitle">Contact us</h1>
        <div className="main-text">
          <ul>
            <li>
              While we pin point areas of concern, please contact us with
              support questions.
            </li>
            <li>
              <GetInTouch />
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
const Sidebar = () => {
  return (
    <div className="sidebar">
      <ul>
        {sidebarItems.map((item) => (
          <SidebarItem key={item.id} label={item.label} target={item.target} />
        ))}
      </ul>
    </div>
  );
};

const SidebarItem = ({ label, target }) => {
  return (
    <li className="sidebar-item">
      <ScrollLink to={target} smooth={true} duration={500} offset={-130}>
        {label}
      </ScrollLink>
    </li>
  );
};
const ResourceInfo = () => {
  return (
    <div className="resource-container">
      <Sidebar />
      <MainContent />
    </div>
  );
};

const Resources = () => {
  return (
    <div>
      <Header />
      <ResourceInfo />
      <Footer />
    </div>
  );
};

export default Resources;
