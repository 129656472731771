import Header from "../components/Header";
import Landing from "../components/Landing";
import Footer from "../components/Footer";
import GetInTouch from "../components/GetInTouch.jsx";
import ExploreDemo from "../components/ExploreDemo.jsx";

const Home = () => {
  return (
    <div className="App">
      <Header />
      <Landing />
      {/* <GetInTouch /> */}
      <Footer />
    </div>
  );
};

export default Home;
